import React from "react";
import { Router, Switch } from "react-router-dom";
import RouteComponent from "./route-config.jsx";
import { UrlCollection } from "./common/url-collection";
import history from "./common/history";

//--- Loading
import AppLoading from "./components/loading/loading.view";

//--- Layout
import LayoutView from "./components/layout/layout.view";
import LayoutViewWithHook from "./components/layout/layout.view.withHook";
import LayoutUserView from "./components/layout/layout-user.view.jsx";


import Login from "./modules/login/login.view.jsx";
import ForgotPassword from "./modules/forgot-password/forgot-password.view.jsx";
import ResetPassword from "./modules/reset-password/reset-password.view.jsx";
import UserManagement from "./modules/user-management/user-management.view";
import CreatMapView from "./modules/create-map/create-map"

import Floods from "./modules/floods/floods.view.jsx";
import LandSlide from "./modules/landslide/landslide.view.jsx";
import News from "./modules/news/news.view.jsx";
import MapData from "./modules/map-data/map-data.view.jsx";
import InitMapDataView from "./modules/init-map-data/init-map-data";

import DialogWarningExpired from "./components/dialog-warning-expired/dialog-warning-expired.view";
import MyAccount from "./modules/my-account/my-account.view.jsx";
// import { useMediaQuery } from "react-responsive";

function App() {
  //media query
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: "(min-width: 1224px)",
  // });
  // const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  // const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  // const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  // const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  // const reactMediaQuery = {
  //   isDesktopOrLaptop:isDesktopOrLaptop,
  //   isBigScreen:isBigScreen,
  //   isTabletOrMobile:isTabletOrMobile,
  //   isPortrait:isPortrait,
  //   isRetina:isRetina
  // }

  return (
    <div>
      <DialogWarningExpired />
      <Router history={history}>
        <AppLoading />

        <Switch>
          <RouteComponent
            exact
            layout={LayoutUserView}
            component={Login}
            path={UrlCollection.Login}
            isSetActive={false}
          />
                    <RouteComponent
            exact
            layout={() => (
              <LayoutViewWithHook title="Quản lý tài khoản">
                <MyAccount/>
              </LayoutViewWithHook>
            )}
            path={UrlCollection.MyAccount}
          />
          <RouteComponent
            exact
            layout={LayoutUserView}
            component={ForgotPassword}
            path={UrlCollection.ForgotPassword}
            isSetActive={false}
          />
          <RouteComponent
            exact
            layout={LayoutUserView}
            component={ResetPassword}
            path={UrlCollection.ResetPassword}
            isSetActive={false}
          />


          <RouteComponent
            exact
            layout={() => (
              <LayoutViewWithHook title="Tin tức">
              <News isQHT/>
            </LayoutViewWithHook>
            )}
            path={UrlCollection.Home}
          />
          
          <RouteComponent
            exact
            layout={() => (
              <LayoutViewWithHook title="Tin tức">
              <News isQHT/>
            </LayoutViewWithHook>
            )}
            path={UrlCollection.News}
          />
        <RouteComponent
            exact
            layout={() => (
              <LayoutViewWithHook title="Thông tin lũ lụt">
                <Floods />
              </LayoutViewWithHook>
            )}
            path={UrlCollection.Floods}
          />
        <RouteComponent
            exact
            layout={() => (
              <LayoutViewWithHook title="Thông tin sạt lở ">
                <LandSlide />
              </LayoutViewWithHook>
            )}
            path={UrlCollection.LandSlide}
          />
          {/* Map */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutUserView title="Bản đồ">
                <CreatMapView />
              </LayoutUserView>
            )}
            path={UrlCollection.MapData}
          />
          <RouteComponent
            exact
            layout={() => (
              <LayoutViewWithHook title="Quản lý người dùng">
                <UserManagement />
              </LayoutViewWithHook>
            )}
            path={UrlCollection.UserManagement}
          />    
        </Switch>
      </Router>
    </div>
  );
}

export default App;
