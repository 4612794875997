export const UrlCollection = {
  Home: "/",

  //News
  News: '/tin-tuc',
  Floods: '/thong-tin-lu-lut',
  LandSlide: '/thong-tin-sat-lo',
  UserManagement: '/he-thong/quan-ly-nguoi-dung',
  DocumentManagement: '/document-management',
  Log: '/he-thong/log',
  //--- Account
  Login: '/dang-nhap',
  Register: '/dang-ky',
  ForgotPassword: '/quen-mat-khau',
  ConfirmCode: '/xac-nhan',
  ResetPassword: '/dat-lai-mat-khau',
  MapData: '/ban-do',
  AccessDenied: '/403',
  OpinionForm: '/he-thong/tao-form-y-kien',
  MyAccount: '/quan-ly-tai-khoan',
};
