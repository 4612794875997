import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApiUrl } from '../../../api/api-url';

//--- Action
import * as landslideAction from "../../../redux/store/landslide/landslide.store";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import * as viVN from "../../../language/vi-VN.json";
import MapComponent from "../map.view";
//--- Material Control
import {
    DialogActions,
    TextareaAutosize,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    makeStyles,
    Typography,
    IconButton,
    Select,
    MenuItem,
    Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import FileInputComponent from "../../../components/file-input/file-input.view";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
    NotificationMessageType,
    APIUrlDefault,
    MaxSizeImageUpload,
} from "../../../utils/configuration";
import * as appActions from "../../../core/app.store";
import FileManagement from "../../../components/file_management/file_management";
//--- Styles
import "../landslide.scss";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function EditLandslide(props) {
    const classes = useStyles();

    const {
        isOpen,
        onClose,
        onSuccess,
        landslideId,
        setOrder,
        setOrderBy,
        GetListAll,
        rowsPerPage,
    } = props;

    const [landslideModel, setLandslideModel] = useState();
    const [placeName, setplaceName] = useState();
    const [lat, setlat] = useState();
    const [lng, setlng] = useState();
    const [surveyer, setsurveyer] = useState();
    const [cause, setCause] = useState();
    const [severity, setSeverity] = useState();
    const [isShow, setShow] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesTemp, setFilesTemp] = useState([]);
    const urlUploadImage = APIUrlDefault + ApiUrl.UrlUploadFromEditor;

    useEffect(() => {
        landslideAction
            .GetDetailLandslide(landslideId)
            .then((res) => {
                if (res && res.content) {
                    setLandslideModel(res.content)
                    setplaceName(res.content.placeName);
                    setlat(res.content.lat);
                    setlng(res.content.lng);
                    setsurveyer(res.content.surveyer);
                    setCause(res.content.cause);
                    setSeverity(res.content.severity);
                }
            })
            .catch((err) => console.log(err));
    }, [landslideId]);

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
            return;
        }

        let formData = new FormData();
        formData.append("gid", landslideId);
        formData.append("placeName", placeName);
        formData.append("lat", lat.toString().replace('.',','));
        formData.append("lng", lng.toString().replace('.',','));
        formData.append("surveyer", surveyer);
        formData.append("cause", cause);
        formData.append("severity", severity);

        landslideAction
            .UpdateLandslide(formData)
            .then((result) => {
                if (result) {
                    setOrder("asc");
                    setOrderBy("order");
                    onSuccess();
                    ShowNotification(
                        "Thành công",
                        NotificationMessageType.Success
                    );
                    GetListAll(undefined, undefined, undefined, undefined, undefined, undefined, 1, rowsPerPage, undefined);
                }
            })
            .catch((err) => {
                onSuccess();
                ShowNotification(
                    err.errorMessage,
                    NotificationMessageType.Error
                );
            });
    };

    const onLocationSelect = (lat, lng) => {
        setlat(lat);
        setlng(lng);
    };



    return (
        <div>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Chỉnh sửa</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    {landslideModel && (
                        <DialogContent className="pt-4 pb-2">
                            <div className="form-group">
                                <label className="text-dark">
                                    Khu vực xảy ra<span className="required"></span>
                                </label>
                                <TextField
                                    name="placeName"
                                    error={errors.placeName && errors.placeName.type === "required"}
                                    fullWidth
                                    ref={register({ required: true})}
                                    type="text"
                                    className="form-control"
                                    inputProps={{ maxLength: 150 }}
                                    defaultValue={landslideModel.placeName}
                                    onChange={(e) => setplaceName(e.target.value)}
                                />
                                {errors.placeName && errors.placeName.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                            </div>
                            <div className="form-group" hidden>
                                <label className="text-dark">
                                    Latitude<span className="required"></span>
                                </label>
                                <TextField
                                    name="lat"
                                    ref={register({ required: true})}
                                    error={errors.lat && errors.lat.type === "required"}
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    inputProps={{ maxLength: 150 }}
                                    value={lat}
                                />
                                {errors.lat && errors.lat.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                            </div>
                            <div className="form-group" hidden>
                                <label className="text-dark">
                                    Longtitude<span className="required"></span>
                                </label>
                                <TextField
                                    name="lng"
                                    ref={register({ required: true})}
                                    error={errors.lng && errors.lng.type === "required"}
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    inputProps={{ maxLength: 150 }}
                                    value={lng}
                                />
                                {errors.lng && errors.lng.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="text-dark">Nội dung</label>
                                <TextField
                                    name="surveyer"
                                    fullWidth
                                    ref={register({ required: false})}
                                    type="text"
                                    className="form-control"
                                    defaultValue={landslideModel.surveyer}
                                    onChange={(e) => setsurveyer(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label className="text-dark">Mức độ </label>
                                <TextField
                                    name="severity"                                    
                                    type="text"
                                    ref={register({ required: false})}
                                    className="form-control"
                                    defaultValue={landslideModel.severity}
                                    onChange={(e) => setSeverity(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label className="text-dark">Nguyên nhân </label>
                                <TextField
                                    name="cause"
                                    ref={register({ required: false})}
                                    type="text"
                                    className="form-control"
                                    defaultValue={landslideModel.cause}
                                    onChange={(e) => setCause(e.target.value)}
                                />
                            </div>
                            <MapComponent setlat={setlat} setlng={setlng} lat={lat} lng={lng} onLocationSelect={onLocationSelect} />
            
                        </DialogContent>
                    )}
                    <DialogActions className="border-top pt-3">
                        <Button variant="contained" color="default" onClick={onClose}>
                            Hủy
                        </Button>
                        <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />}>
                            Lưu
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        // State mappings here
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        landslideAction: bindActionCreators(landslideAction, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLandslide);
