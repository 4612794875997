export const ApiUrl = {
  //--- Account
  Login: "api/admin/Account/Login",
  Register: "api/Account/Register",
  ForgotPassword: "api/Account/ForgotPassword",
  ResetPassword: "​api​/Account​/ResetPassword",
  GetUserAccountDetail: "api/Account/GetUserAccountDetail",
  UpdateUserAccountDetail: "api/admin/Account/UpdateUserAccount",
  UpdateUserAccountPassword: "/api/Account/ChangePassword",
  GetScreenAllow: "api/admin/Permission/GetScreenAlow",

  //--- Map
  CreateMap: "api/admin/Map/create-map",
  GetMapById: "api/admin/Map/get-map-by-id",
  GetMapAnalysisById: "api/admin/Map/get-map-analysis-by-id",
  GetAllBaseMapSetting: "api/admin/BaseMapSetting/get-all",
  GetPgSchema: "api/admin/PgTable/get-pg-schema",
  GetPgTable: "/api/admin/DisplayDictionary/LookUpByTableName",


  //MapLayer
  CreateLayer: "api/admin/Layer/Create",
  UpdateLayer: "api/admin/Layer/Update",
  DeleteLayer: "api/admin/Layer/Delete",
  UpdateLayerLevel: "/api/admin/Layer/UpdateLevel",
  GetDetailLayer: "api/admin/Layer/GetDetail",
  AutoCuPlanning: "api/admin/Planning/AutoCreateOrUpdatePlanning",
  CreateBaseOnExistLayer: "api/admin/Layer/CreateBaseOnExistLayer",

  // User Management
  GetListUserManagement: "api/admin/User/GetUserManagement",
  GetDetailUserManagement: "api/admin/User/GetUserDetail",
  CreateUserManagement: "api/admin/User/CreateUser",
  UpdateUserManagement: "api/admin/User/UpdateUser",
  DeleteUserManagement: "api/admin/User/DeleteUser",
  ActiveUserManagement: "api/admin/User/ActiveUser",
  ResetPasswordUserManagement: "api/admin/User/ResetPassword",
  GetRoleLookupUserManagement: "api/admin/Role/GetRoleLookup",
  ActiveUser: "api/admin/User/ActiveUser",
  DeActiveUser: "api/admin/User/DeActiveUser",
  CreateWithMultiRoles: "api/admin/User/CreateUserMutiRoles",
  UpdateWithMultiRoles: "api/admin/User/UpdateUserMutiRoles",

  //Document
  GetAllDocumentByPlanning: "api/admin/Document/GetAllDocumentByParentId",
  CreateFolder: "api/admin/DocumentUpload/CreateDocumentFolder",
  ReNameDocument: "api/admin/Document/RenameDocument",
  CreateFile: "api/admin/DocumentUpload/UploadDocument",
  RemoveDocument: "api/admin/Document/DeleteDocument",
  MoveDocument: "api/admin/Document/MoveDocument",
  SearchDocument: "api/admin/Document/SearchDocument",
  SetDocumentStatus: "api/admin/Document/SetDocumentStatus",
  DownloadDocument: "api/admin/Document/DownloadDocument",
  GetAllFolder: "api/admin/Document/ListAllFolder",
  GetDocumentByFolder: "api/admin/Document/GetDocumentByFolder",

  //utils-shape-file
  Shape_File_Step_1: "",
  Shape_File_Step_2: "",
  Shape_File_Step_3: "",
  Shape_File_Step_4: "",

  //utils-tiff-file
  Import_Tiff_File: "",

  Check_Exist_Layer_Name :"api/admin/Utils/check-exist-layer-name",
  //News
  News_Create: "api/admin/News/Create",
  News_Update: "api/admin/News/Update",
  News_Delete: "api/admin/News/Delete",
  News_ChangeActiveStatus: "api/admin/News/ChangeActiveStatus",
  News_ChangeHotStatus: "api/admin/News/ChangeHotStatus",
  News_ChangeFeatureStatus: "api/admin/News/ChangeFeatureStatus",
  News_GetListAll: "api/admin/News/GetListAll",
  News_GetListByCat: "api/admin/News/GetListByCat",
  News_GetListHot: "api/admin/News/GetListHot",
  News_GetListByStatus: "api/admin/News/GetListByStatus",
  News_GetDetail: "api/admin/News/GetDetail/{id}",

  DownloadFile: "api/cms/Planning/DownloadFileBinary",
  //--- Document Repository
  DocumentRepositoryCreateFolder:
    "api/admin/DocumentUpload/CreateDocumentFolder",
  DocumentRepositoryUploadDocument: "api/admin/DocumentUpload/UploadDocument",
  DocumentRepositoryDownloadDocument:
    "api/admin/DocumentUpload/DownloadDocument",
  DocumentRepositoryDeleteDocument: "api/admin/DocumentUpload/DeleteDocument",
  DocumentRepositoryGetAllDocumentByParentId:
    "api/admin/DocumentUpload/GetAllDocumentByParentId",
  DocumentRepositoryMoveDocument: "api/admin/DocumentUpload/MoveDocument",
  DocumentRepositorySearchDocument: "api/admin/DocumentUpload/SearchDocument",
  DocumentRepositoryRenameDocument: "api/admin/DocumentUpload/RenameDocument",
  DocumentRepositoryGetParentDocumentById:
    "api/admin/DocumentUpload/GetParentDocumentById",
  UrlUploadFromEditor: "api/admin/DocumentUpload/UploadImage",
  InitFolder: "api/admin/DocumentUpload/InitFolder",

  // Security Matrix
  GetSecurityMatrixDetail: "api/admin/Permission/GetSecurityMatrixDetail",
  GetActionLookup: "api/admin/Permission/GetActionLookup",
  GetScreenLookup: "api/admin/Permission/GetScreenLookup",
  UpdateSecurityMatrix: "api/admin/Permission/UpdateSecurityMatrix",

  GetDefaultCordinate: "api/cms/Administrative/GetDefaultCordinate",
  UpdateDefaultCordinate: "api/admin/Administrative/UpdateDefaultCordinate",
  GetClientSetting: "api/cms/Administrative/GetClientSetting",
 
  //GeoGIS
  UpdateGeogisColumnData: "/api/admin/GeoGIS/UpdateGeogisColumnData",
  //getDetailAdmin
  GetDetailAcc: "api/account/GetUserAccountDetail",

    Floods_Create: "api/admin/Floods/Create",
    Floods_Update: "api/admin/Floods/Update",
    Floods_Delete: "api/admin/Floods/Delete",
    Floods_GetDetail: "api/admin/Floods/GetDetail/{id}",
    Floods_GetListAll: "api/admin/Floods/GetListAll",

    Landslide_Create: "api/admin/LandSlides/Create",
    Landslide_Update: "api/admin/LandSlides/Update",
    Landslide_Delete: "api/admin/LandSlides/Delete",
    Landslide_GetDetail: "api/admin/LandSlides/GetDetail/{id}",
    Landslide_GetListAll: "api/admin/LandSlides/GetListAll",
};
