const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  // DOMAIN SERVER MỚI
  development: {
    api: "http://localhost:5002/",
    media_url: "http://localhost:5002/",
    domainAdminSide: "http://localhost:3000",
    domainUserSide: "http://localhost:3000",
    domainName: "",
    workSpace: "hue",
    wmsBaseLink: "https://geo3.cgis.asia/geoserver/hue/wms/",
    datastore: 'hue',
    gwcUrl: 'https://geo3.cgis.asia/geoserver/gwc/service/wms/'
  },
    production: {
      api: "https://hueflood-api.tqtrang67.website/",
      media_url: "https://hueflood-api.tqtrang67.website/",
      domainAdminSide: "https://hueflood-admin.tqtrang67.website",
      domainUserSide: "https://hueflood.tqtrang67.website",
      domainName: "tqtrang67.website",
      workSpace: "hue",
      wmsBaseLink: "https://geo3.cgis.asia/geoserver/hue/wms/",
      datastore: 'hue',
   gwcUrl: 'https://geo3.cgis.asia/geoserver/gwc/service/wms/'

  }
};

module.exports = apiEnvironment[env];