import React, { useState, useEffect } from "react";
import { Configs } from "../../common/config";
import { useForm } from "react-hook-form";

//--- Material Control
import { Button } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";

//--- Styles
import "./floods.scss";

//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever";
import AddCircle from "@material-ui/icons/AddCircle";

//--- Component
import ListFloods from "./list-floods/list-floods.view";
import AddFloods from "./add-floods/add-floods.view";
import EditFloods from "./edit-floods/edit-floods.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
import SearchFloods from "./search-floods/search-floods.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as floodsAction from "../../redux/store/floods/floods.store";
import vi from "../../language/vi";

const useStyles = makeStyles((theme) => ({
  positionRelative: {
    position: "relative",
    '@media (max-width: 1224px)': {
      top:'-15px',
    }
  },
  btnAdd: {
    display: "flex",
    position: "absolute",
    top: -52,
    zIndex: 10,
    right: 0,
    color: "#3f51b5",
    cursor: "pointer",
  },
  btnAddContent : {
    borderBottom: "2px solid #3f51b5",
    '@media (max-width: 1224px)': {
      fontSize : '14px',
    }
  },
  mr05: {
    marginRight: 5,
  },
}));

export default function Floods(props) {
  const classes = useStyles();
  const { isQHT, isQHCC, isQHHTKT } = props;
  const [floodsModels, setFloodsModels] = useState();
  const [totalItemCount, setTotalItemCount] = useState();
  const [searchData, setSearchData] = useState({
    categoryId: 1,
    title: "",
    description: "",
    status: 2,
  });
  const [floodsId, setFloodsId] = useState();
  const [floodsxmin, setFloodsxmin] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("modifiedDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);

  const [isDelete, setIsDelete] = useState(0);
  const [isShowTrash, setIsShowTrash] = useState(false);

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    GetListAll();
  }, []);

  const GetListAll = (
    categoryId = 1,
    title,
    isHot = undefined,
    isFeature = undefined,
    isDelete = 0,
    status = undefined,
    pageIndex = 1,
    pageSize,
    sortExpression
  ) => {
    setPage(pageIndex - 1);
    floodsAction
      .GetListAll(
        title,
        pageIndex,
        pageSize,
        sortExpression,
      )
      .then((res) => {
        if (res && res.content) {
          setFloodsModels(res.content.items);
          setTotalItemCount(res.content.totalItemCount);
        }
      })
      .catch((err) => {
        ShowNotification(
          viVN.Errors.AccessDenied,
          NotificationMessageType.Error
        );
      });
  };

  //--- Dialog
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleOpenEditDialog = (floodsId) => {
    console.log("handleOpenEditDialog", floodsId)
    setFloodsId(floodsId);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (floodsId, floodsxmin) => {
    setFloodsId(floodsId);
    setFloodsxmin(floodsxmin);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const ShowNotificationError = (messages) => {
    ShowNotification(messages, NotificationMessageType.Error);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;


  const handleDelete = () => {
    floodsAction.DeleteFloods(floodsId, floodsxmin).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            searchData.categoryId,
            searchData.title,
            undefined,
            undefined,
            isDelete,
            searchData.status,
            1,
            rowsPerPage,
            orderBy + " " + order
          );
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.FloodsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        handleCloseDeleteDialog();
        err &&
          err.errorType &&
          ShowNotification(
            err.errorMessage,
            NotificationMessageType.Error
          );
      }
    );
  };

  const handelChangeFeature = (floodsId, value) => {
    alert("change " + floodsId + " feature to" + value);
  };
  const handleClearAllField = () => {
    refresh();
    setFilterSection(null);
  };

  const onSubmit = async (data) => {
    await GetListAll(
      searchData.categoryId,
      searchData.title,
      undefined,
      undefined,
      isDelete,
      searchData.status,
      1,
      rowsPerPage,
      orderBy + " " + order
    );
    handleCloseFilter();
  };

  const refresh = () => {
    setSearchData({
      categoryId: 1,
      title: "",
      description: "",
      status: 2,
    });
    setOrderBy("modifiedDate");
    setOrder("desc");
    GetListAll(
      1,
      "",
      undefined,
      undefined,
      isDelete,
      undefined,
      1,
      rowsPerPage,
      orderBy + " " + order
    );
  };




  const handleChangeSearchForm = (event) => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };



  return (
    <div className={`slider ${classes.positionRelative}`}>
      <div className={`${classes.btnAdd}`} onClick={handleOpenAddDialog}>
      <span className={`${classes.btnAddContent}`}> 
        <AddCircle className={classes.mr05} />
        Thêm Tin
      </span>
      </div>

      <SearchFloods
        isQHCC={isQHCC}
        isQHT={isQHT}
        isQHHTKT={isQHHTKT}
        searchData={searchData}
        onChangeDescription={handleChangeSearchForm}
        onChangeTitle={handleChangeSearchForm}
        onChangeStatus={handleChangeSearchForm}
        refresh={refresh}
        onSubmit={onSubmit}
      />
      {floodsModels ? (
        <ListFloods
          isQHCC={isQHCC}
          isQHT={isQHT}
          isQHHTKT={isQHHTKT}
          totalItemCount={totalItemCount}
          floodsModels={floodsModels}
          GetListAll={GetListAll}
          title={searchData.title}
          categoryId={searchData.categoryId}
          isDelete={isDelete}
          editAction={handleOpenEditDialog}
          deleteAction={handleOpenDeleteDialog}
          restoreAction={null}
          changeFeatureAction={handelChangeFeature}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      ) : (
        ""
      )}
      {openAddDialog && (
        <AddFloods
          isQHCC={isQHCC}
          isQHT={isQHT}
          isQHHTKT={isQHHTKT}
          isOpen={openAddDialog}
          onClose={handleCloseAddDialog}
          onSuccess={handleCloseAddDialog}
          ShowNotificationError={ShowNotificationError}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      )}

      {openEditDialog && (
        <EditFloods
          isQHCC={isQHCC}
          isQHT={isQHT}
          isQHHTKT={isQHHTKT}
          isOpen={openEditDialog}
          onClose={handleCloseEditDialog}
          onSuccess={handleCloseEditDialog}
          floodsId={floodsId}
          ShowNotificationError={ShowNotificationError}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      )}

      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={"Xác nhận xóa"}
          content={"Bạn có chắc chắn muốn xóa?"}
        />
      )}
    </div>
  );
}
