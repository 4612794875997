import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListAll = (title="",
    pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "modifiedDate desc") => {
    const params = new URLSearchParams();
    params.append('pageIndex',pageIndex);
    params.append('pageSize',pageSize);
    params.append('sorting',sortExpression);
    title && params.append('title',title);
    return service.get(ApiUrl.Landslide_GetListAll,params).then(res => { return res }).catch(err => { throw err });
}

export const GetDetailLandslide = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.get(ApiUrl.Landslide_GetDetail.replace("{id}",id)).then(res => { return res }).catch(err => { throw err });
}

export const CreateLandslide = (body) => {
    return service.post(ApiUrl.Landslide_Create, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateLandslide = (body) => {
    return service.post(ApiUrl.Landslide_Update, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteLandslide = (id) => {

    return service.postParams(ApiUrl.Landslide_Delete + "/" + id ).then(res => { return res }).catch(err => { throw err });
}

